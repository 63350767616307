"use client";

import classNames from "classnames/bind";
import { CosmosButton } from "@cosmos/web/react";
import { BlocksProductTeaserCard } from "../../../__generated__/contentful-types";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { Kicker } from "../../kicker/kicker";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import styles from "./organics-teaser-card.module.css";
// import { DietaryMarks } from "../../dietary-marks/dietary-marks";

const cx = classNames.bind(styles);

export const OrganicsTeaserCard = ({
  block,
}: {
  block: BlocksProductTeaserCard;
}) => {
  const imageLoading = useImageLoading();

  return (
    <div
      className={cx("container")}
      style={{
        "--card-bg-color": block.backgroundColor ?? undefined,
      }}
    >
      <div className={cx("inner")}>
        <div
          className={cx(`card`, {
            "card--bg-color": block.backgroundColor,
          })}
        >
          <div className={cx("text-content")}>
            {block.logoHeading?.url && (
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  block.logoHeading.url,
                )}
                sizes="(min-width: 650px) 225px, 177px" // Manually kept in-sync with `.logo`
                className={cx("logo")}
                alt={block.logoHeading.description || "Organics logo"}
              />
            )}
            <div>
              {block.name && (
                <span className={cx("kicker-wrapper")}>
                  <Kicker kind="bare" text={block.name} />
                </span>
              )}
              <p className={cx("text")}>{block.description}</p>
              {block.linkUrl && block.linkText && (
                <div className={cx("button-wrapper")}>
                  <CosmosButton
                    href={block.linkUrl ?? undefined}
                    kind="tertiary"
                    size="large"
                    className={cx("button")}
                  >
                    {block.linkText}
                  </CosmosButton>
                </div>
              )}
            </div>
          </div>
          {block.image?.url && (
            <div className={cx("image-wrapper")}>
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  block.image.url,
                )}
                sizes="(min-width: 1000px) 581px, (min-width:500px): 570px, 95vw" // Manually kept in sync with `.image` and `.card`
                className={cx("image")}
                alt={block.image.description || "Organics new range"}
              />
              {/* Vegetarian mark currently not implimented */}
              {/* <DietaryMarks
                kind="vegetarian"
                className={cx("dietary-mark-vegetarian")}
              /> */}
            </div>
          )}
          {block.backgroundImage?.url && (
            <div className={cx("bg-image-wrapper")}>
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  block.backgroundImage.url,
                )}
                sizes="(min-width: 1000px) 978px, (min-width: 500px) 600px, 100vw" // Manually kept in sync with `.bg-image-wrapper` and `.card`
                className={cx("bg-image")}
                alt={block.backgroundImage.description || "Organics new range"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
